@import "./../../colors";
@import "./../../responsive";

.navbar {
  border-bottom: 1px solid $light-gray;

  @include widescreen {
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
  }
}
