@import "./../../responsive";
@import "./../../colors";

.container {
  display: flex;
  flex-direction: column;
}

.product {
  min-height: 256px;
  display: flex;
}

.productActive {
  @include mobile {
    flex-direction: column;
  }
}

.right {
  justify-content: flex-end;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    font-size: 2rem;
    font-weight: 200;
    text-transform: uppercase;
    cursor: pointer;
  }
}

.image {
  width: 200px;
  height: 200px;
  border-radius: 9999px;
  background-size: cover;
  transition: background-image 0.2s ease-in-out;
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  
  @include mobile {
    width: 128px;
    height: 128px;
    margin: 1rem;
  }
}

.active {
  width: 600px;
  height: 600px;
  border-radius: 0;
  filter: drop-shadow(1px 1px 1px $light-gray);

  @include mobile {
    width: 200px;
    height: 200px;
  }
}

.solei0 {
  background-image: url('./solei.jpg');
}
.solei1 {
  background-image: url('./solei1.jpg');
}
.solei2 {
  background-image: url('./solei2.jpg');
}

.plise0 {
  background-image: url('./plise.jpg');
}
.plise1 {
  background-image: url('./plise1.jpg');
}
.plise2 {
  background-image: url('./plise2.jpg');
}

.frize0 {
  background-image: url('./frize.jpg');
}
.frize1 {
  background-image: url('./frize1.jpg');
}
.frize2 {
  background-image: url('./frize2.jpg');
}

.description {
  max-width: 500px;
  color: $gray;
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 2rem;
  font-weight: 200;

  p {
    padding-top: 0.25em;
  }

  @include mobile {
    font-size: 1rem;
  }
}

.descriptionRight {
  align-items: flex-end;
  text-align: right;

  @include mobile {
    align-items: flex-start;
  }
}

.descriptionActive {
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 800px;
  text-align: left;
}

.gallery {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: left;
  margin-top: 0.5rem;
}

.galleryItem {
  background-color: $orange;
  width: 20px;
  height: 20px;
  border-radius: 9999px;
  margin-right: 0.5rem;
  filter: drop-shadow(1px 1px 1px $light-gray);
  transition: background-color 0.1s ease-in;
}

.galleryItem {
  &:hover,&:active {
    background-color: $light-gray;
  }
}

.red {
  background-color: $red;
}

.yellow {
  background-color: $yellow;
}

.orange {
  background-color: $orange;
}

.back {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  text-transform: uppercase;
  font-size: 1rem;
  color: $gray !important;

  &:hover {
    text-decoration: underline;
  }
}

.animIcon {
  height: 36px;
  width: 36px;
  cursor: pointer;
}

.example {
  margin-top: 1rem;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}