@import "./../../colors";
@import "./../../responsive";

.container {
  color: $white;
  
  p {
    padding-bottom: 0.5rem;
  }

  @include mobile {
    background-color: $white;
    color: $gray;
  }
}

.individual {
  h1 {
    text-transform: uppercase;
    @include mobile {
      color: $gray !important;
    }
  }

  padding: 2rem;
  @include mobile {
    padding: 0.5rem;
  }
}

.business {
  border-radius: 0;

  h1 {
    text-transform: uppercase;
    color: $gray !important;
  }

  padding: 2rem;
  @include mobile {
    padding: 0.5rem;
  }
}

.example {
  text-decoration: underline;
}