@import "./../../colors";
@import "./../../responsive";

.container {
  text-align: center;
}

.contact {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @include mobile {
    flex-direction: column;
  }
}

.item {
  font-size: 1.5rem;
  font-weight: 200;

  @include mobile {
    padding-bottom: 1rem;
  }
}

.name {
  font-weight: 200;
  font-size: 2.8em;
}

.phone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;

  p {
    margin: 1rem;
  }
}

.personalName {
  font-weight: 400;
}