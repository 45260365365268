:global {
  .slider-list, .slider-frame {
    min-height: 400px;
  }
}

.container {
  background-color: white;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  font-weight: 200;
  appearance: none;
  border: none;
  outline: none;
  background-color: white;
  width: 50px;
  height: 50px;
  padding: 0;
  border-radius: 9999px;
  margin: 0.5rem;
  opacity: 0.5;

  &:hover {
    opacity: 0.85;
  }

  &:active {
    opacity: 0.5;
  }
}
