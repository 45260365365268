@import "./../../responsive";
@import "./../../colors";

.heroBody {
  margin: 0;
  padding: 0;
}

.container {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.text {
  background-color: #fff;
  opacity: 0.9;
  padding: 4.5rem;

  h1, h2 {
    color: #000;
  }

  @include mobile {
    padding: 0.5rem;
    margin: 0;
  }
}

.video {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: -1;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  object-fit: cover;
  object-position: center;
}

.company {
  font-size: 3rem;
  font-weight: 200;
  text-align: center;
  width: 100%;

  @include mobile {
    font-size: 3rem;
  }
}

.title {
  font-size: 2.25rem;
  font-weight: 400;
  width: 100%;
  text-align: center;

  @include mobile {
    font-size: 2rem;
  }
}

.subtitle {
  font-size: 1.5rem;
  font-weight: 200;
  width: 100%;
  text-align: center;

  @include mobile {
    font-size: 1.5rem;
  }
}