.container {
  max-width: 900px;
}

.title {
  text-align: center;
  text-transform: uppercase;
  font-size: 2.5rem;
}

.faqTitle {
  font-size: 1rem;
  font-weight: 600;
}

.faqAnswer {
  padding-top: 0.5rem;
}
